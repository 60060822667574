import "./App.css";
import React, { useEffect } from "react";
import { AppRoutes } from "./AppRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "./store";
import { asyncFetchDevelopments, getAllDevelopments, getAllDevelopmentsStatus, setPriceOriginal } from "../features/developments/developmentsSlice";
import { setAdminOn, setLang, setPermission } from "../features/app/UserRelatedSlice";
import { checkPermission } from "../api/user";
import { setIsMobile, setIsRealMobile, setIsSmallScreen, setIsWidthHideFilter } from "../features/app/UIRelatedSlice";
import HtmlOverflowManager from "../components/HtmlOverflowManager";
import { preloadImagesIfNeeded } from "../util/loadResources/imagePreloader";
import { debounce } from '../util/debounce';







export const App = () => {
    const dispatch = useDispatch<AppDispatch>();
    const allDevelopments = useSelector(getAllDevelopments);
    const developmentsStatus = useSelector(getAllDevelopmentsStatus);

    useEffect(() => {
        preloadImagesIfNeeded();
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const languageParam = urlParams.get("language");

        if (languageParam) {
            const isEnglish = languageParam === "english";
            dispatch(setLang(isEnglish));
            localStorage.setItem("language", isEnglish ? "english" : "other");
        } else {
            const storedLanguage = localStorage.getItem("language");
            if (storedLanguage) {
                dispatch(setLang(storedLanguage === "english"));
            } else {
                dispatch(setLang(true));
                localStorage.setItem("language", "english");
            }
        }

    }, []);



    useEffect(() => {
        if (developmentsStatus === "idle") {
            dispatch(asyncFetchDevelopments());
        }
    }, [dispatch, developmentsStatus]);

    const _getMinMaxPrice = (developments: any) => {
        const prices = Object.values(developments).map((data: any) => ({
            max: data.maxPrice,
            min: data.minPrice && data.minPrice !== 1 && data.minPrice !== 0 ? data.minPrice : Infinity
        }));

        const maxPrice = Math.max(...prices.map(p => p.max));
        const minPrice = Math.min(...prices.map(p => p.min));

        return [minPrice, maxPrice];
    };

    useEffect(() => {
        // WHEN FIRST LOADED //
        if (allDevelopments && Object.keys(allDevelopments).length > 0) {
            const [minPrice, maxPrice] = _getMinMaxPrice(allDevelopments);
            dispatch(setPriceOriginal([minPrice, maxPrice]));
        }
    }, [allDevelopments, dispatch]);




    useEffect(() => {
        (async () => {
            try {
                const response = await checkPermission();
                if (response.status === 200) {
                    dispatch(setPermission(5));
                    return;
                }
            } catch (error) {
                // console.error(error);
            }
        })();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const isAdminParam = queryParams.get("admin");

        if (isAdminParam === "on") {
            dispatch(setAdminOn(true));
        }
    }, []);



    useEffect(() => {
        const handleResize = debounce(() => {
            dispatch(setIsMobile(window.innerWidth < 650));
            dispatch(setIsRealMobile(window.innerWidth < 500));
            dispatch(setIsSmallScreen(window.innerWidth < 870));
        }, 300);

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);









    useEffect(() => {
        setIsWidthHideFilter(window.innerWidth < 920);
    }, []);

    useEffect(() => {
        let resizeTimer: ReturnType<typeof setTimeout>;

        const handleResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                setIsWidthHideFilter(window.innerWidth < 920);
            }, 300);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            if (resizeTimer) clearTimeout(resizeTimer);
        };
    }, []);





    return (
        <>
            <HtmlOverflowManager />
            <AppRoutes />
        </>
    );
};

