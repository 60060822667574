import React from "react";
import Contact from "../../pages/Contact/Contact";
import { useDispatch, useSelector } from "react-redux";
import { getIsMobile, setShowContactPopup } from "../../features/app/UIRelatedSlice";

export default function ContactUs() {
    const isMobile = useSelector(getIsMobile);
    const dispatch = useDispatch();
    return (
        <div className={`fixed max-h-[95vh] overflow-y-scroll ${isMobile ? "top-[5vh] left-[2.5vw] w-[95vw] h-[82.5vh] overflow-y-scroll" : "top-[50vh] left-[50vw] -translate-x-[50%] -translate-y-[50%]"} bg-white p-8 rounded-lg shadow-2xl border border-gray-500/10 shadow-gray-500/5 z-[9999999999999999999]`}>
            <p onClick={() => { dispatch(setShowContactPopup(false)); }} className="absolute top-6 right-6 text-secondary-light/50 hover:cursor-pointer">✕</p>
            <Contact page="popup" />
        </div>
    );
}