// Libraries
import React, { useContext, useState, Suspense, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

// React components
import TubeView from "./Views/TubeView";
import ListView from "./Views/ListView";
import TableView from "./Views/TableView";
// import MapView from "./Views/MapView";
import Filter from "./Controls/Filter";
import MenuBar from "./Controls/MenuBar";

// Redux slices
import { AppDispatch } from "../../app/store";
import {
  clearFilter,
  getAllDevelopmentsStatus,
  getKeywordFilteredDevelopmentIds,
  getSortedDevelopmentIds,
} from "../../features/developments/developmentsSlice";
import InfoCollection from "../../items/InfoCollection/InfoCollection";
import Help from "../../items/Help/Help";
import {
  getLayoutHeaderHeight,
  getLayoutMenubarHeight,
  setLayoutValue,
} from "../../features/layouts/layoutsSlice";
import { preloadImage } from "../../util/loadResources/imagePreloader";
import imgMap from "../../assets/media/developmentMaps/map.jpeg";
import imgList from "../../assets/media/developmentMaps/list.jpeg";
import styles from "./SalePage.module.css";
import { SpinnerCircles } from "../../util/Spinners/Spinners";
import { getIsMobile, getShowFilter, getShowFilterCarriedOverNotification, setShowActionPopup, setShowFilter, setShowFilterCarriedOverNotification } from "../../features/app/UIRelatedSlice";
import { getLang } from "../../features/app/UserRelatedSlice";
import { getPermission } from "../../features/app/UserRelatedSlice";

const MapView = React.lazy(() => import('./Views/MapView'));

export default function SalePage() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const viewParam = query.get("view");

  const saleContainerRef = useRef<HTMLDivElement | null>(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState(viewParam || "list");
  const showFilter = useSelector(getShowFilter);
  const permission = useSelector(getPermission);
  const lang = useSelector(getLang);
  const showFilterCarriedOverNotification = useSelector(getShowFilterCarriedOverNotification);
  const isMobile = useSelector(getIsMobile);

  const keywordFilteredDevelopmentIds = useSelector(getKeywordFilteredDevelopmentIds);
  const headerHeight = useSelector(getLayoutHeaderHeight);
  const menubarHeight = useSelector(getLayoutMenubarHeight);

  const loadDevStatus = useSelector(getAllDevelopmentsStatus);
  const sortedDevelopmentIds = useSelector(getSortedDevelopmentIds);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [cachedViews, setCachedViews] = useState<{ [key: string]: JSX.Element; }>({});
  const dispatch = useDispatch<AppDispatch>();

  const handleViewChange = useCallback((newView: string) => {
    setView(newView);
  }, []);

  useEffect(() => {
    if (viewParam && !cachedViews[viewParam]) {
      handleViewChange(viewParam);
    }
  }, [viewParam, cachedViews, handleViewChange]);


  useEffect(() => {
    const driver = localStorage.getItem("driver");

    if (driver !== "true") {
      dispatch(setShowActionPopup(true));
      localStorage.setItem("driver", "true");
    }
  }, []);

  // useEffect(() => {
  //   if (view === "map") {
  //     setMapViewMounted(true);
  //   }
  // }, [view]);

  useEffect(() => {
    if (showFilterCarriedOverNotification) {
      const timer = setTimeout(() => {
        dispatch(setShowFilterCarriedOverNotification(false));
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showFilterCarriedOverNotification]);

  // useEffect(() => {
  //   if (!cachedViews[view]) {
  //     setCachedViews((prev) => ({
  //       ...prev,
  //       [view]: <Results />,
  //     }));
  //   }
  // }, [view, cachedViews]);


  // if (loading === "loading") {
  //   return (
  //     <div className=" min-h-screen" style={{ marginTop: menubarHeight }}>
  //       <SpinnerCircles />
  //     </div>
  //   );
  // }


  function toggleFullScreen() {
    if (saleContainerRef.current) {
      const header = document.querySelector('header');
      const footer = document.querySelector('footer');

      if (!isFullScreen) {
        if (header) header.style.display = 'none';
        if (footer) footer.style.display = 'none';
        dispatch(setLayoutValue({ name: "headerHeight", value: 0 }));
        setIsFullScreen(true);
      } else {
        if (header) {
          header.style.display = '';
          dispatch(setLayoutValue({ name: "headerHeight", value: header.clientHeight }));
        }
        if (footer) footer.style.display = '';
        setIsFullScreen(false);
      }
    }
  }

  useEffect(() => {
    if (isMobile) return;
    dispatch(setShowFilter(true));
  }, []);


  return (
    <>
      {loadDevStatus === "loading" &&
        <div
          style={{ height: "calc(100vh - " + (menubarHeight) + "px)" }}
          className=" bg-gray-50 flex items-center justify-center w-full" >
          <SpinnerCircles />
        </div>
      }
      {loadDevStatus === "failed" &&
        <div className="flex flex-col items-center justify-center h-full w-full px-5" style={{ height: "calc(100vh - " + (menubarHeight) + "px)" }}>
          <p className="text-2xl font-semibold text-gray-700 mb-4 text-center">Network Error</p>
          <p className="text-lg text-gray-600 mb-6">Please check your internet connection and try again.</p>
        </div>
      }
      {loadDevStatus === "succeeded" &&
        <div
          className={`max-w-[1800px] h-full mx-auto relative z-0 flex flex-col overflow-hidden`}
          ref={saleContainerRef}
        >
          <div
            className={`fixed top-0 left-0 z-[9999999999999999] w-full h-full flex items-center justify-center ${showFilterCarriedOverNotification ? styles.fadeIn : styles.fadeOut
              } ${showFilterCarriedOverNotification ? "block" : "hidden"}`}
          >
            <div className="p-4 rounded-lg bg-gray-900 bg-opacity-60 shadow-lg">
              <p
                className="text-lg text-white"
                style={{ textShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
              >
                {lang ? "Your filters have been applied!" : "已为您显示筛选后结果"}
              </p>
            </div>
          </div>
          <MenuBar
            count={keywordFilteredDevelopmentIds.length}
            showSort={view === "list"}
            toggleFullScreen={toggleFullScreen}
            isFullScreen={isFullScreen}
            view={view}
            setView={handleViewChange}
          />
          <div
            ref={viewRef}
            className={`relative sale-content grid-svg-container ${showFilter && "mr-[400px]"}`}
            style={{
              minHeight: "calc(100vh - " + (menubarHeight) + "px)",
              // minHeight: "calc(100vh - " + (menubarHeight + headerHeight) + "px)",
              marginTop: menubarHeight - headerHeight + "px",
            }}
          >
            <Results view={view} permission={permission} />
          </div>
          <Filter viewRef={viewRef} />
          <Help
            playTutorial={() => {
              const driverObj = createDriver(lang);
              driverObj.drive();
            }}
          />
        </div>
      }
      <InfoCollection />
    </>
  );
}

const Results = React.memo(({ view, permission }: { view: string, permission: number; }) => {
  const menubarHeight = useSelector(getLayoutMenubarHeight);
  const showFilter = useSelector(getShowFilter);
  return (
    <>
      <div className={` ${view === "list" ? "visible relative" : "invisible absolute"}`}
      // <div className={` ${view === "list" ? "translate-x-0" : "translate-x-full"}`}
      >
        <ListView />
      </div>
      {/* <div className={` ${view === "tube" ? "translate-x-0" : "translate-x-full"}`} */}
      <div className={` ${view === "tube" ? "translate-x-0 visible relative" : "translate-x-full invisible absolute"}`}
      >
        <TubeView />
      </div>
      {permission > 4 && (
        <div className={`absolute inset-0 ${view === "table" ? "visible" : "invisible"}`}
          style={{ height: `calc(100vh - ${menubarHeight}px)` }}
        >
          <TableView />
        </div>
      )}
      <Suspense fallback={<div className="absolute inset-0 flex items-center justify-center"><SpinnerCircles /></div>}>
        <div className={`absolute w-screen inset-0 ${view === "map" ? "visible" : "invisible"}`}
          style={{ height: "calc(100vh - " + menubarHeight + "px)" }}
        >
          <MapView
            rounded={false}
            onHomePage={false}
            isVisible={view === "map"}
          />
        </div>
      </Suspense>
    </>
  );
});




export function createDriver(lang: boolean) {
  return driver({
    popoverClass: "driverjs-theme",
    showProgress: true,
    steps: [
      {
        element: "#driver_views",
        popover: {
          title: lang ? "Views" : "视图",
          description: lang
            ? "This section allows you to switch between different views of the developments."
            : "这个部分允许您在不同的房产项目视图之间切换。",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#driver_filter",
        popover: {
          title: lang ? "Filter" : "筛选",
          description: lang
            ? "Here you can apply various filters to refine the developments displayed."
            : "在这里，您可以应用各种条件来筛选房产项目。",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#driver_download_pdf",
        popover: {
          title: lang ? "Download PDF" : "下载PDF",
          description: lang
            ? "Click here to download the data in PDF format."
            : "点击此处以PDF格式下载筛选结果。",
          side: "bottom",
          align: "start",
        },
      },
      {
        element: "#driver_tools",
        popover: {
          title: lang ? "Tools" : "工具",
          description: lang
            ? "This section contains tools that are useful for (soon-to-be) property owners."
            : "这个部分包含对房产所有者有用的工具。",
          side: "right",
          align: "start",
        },
      },
      {
        popover: {
          title: lang ? "Tour Complete!" : "导览完成！",
          description: lang
            ? "That concludes the tour. Enjoy exploring developments in London!"
            : "导览到此结束。祝您愉快地探索伦敦的开发项目！",
        },
      },
    ],
    prevBtnText: lang ? "Previous" : "上一步",
    nextBtnText: lang ? "Next" : "下一步",
    doneBtnText: lang ? "Done" : "完成",
  });
}
