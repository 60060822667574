import React from "react";
import { IncludeHouseIcon } from "../../../../assets/assetExport";
import "./Info.scss";
import { useSelector } from "react-redux";
import { getLang } from "../../../../features/app/UserRelatedSlice";
import { getIsMobile } from "../../../../features/app/UIRelatedSlice";
import { renderNum } from "../../../../util/formatters";

export default function Info(props: any) {
  const {
    id,
    house,
    name,
    nameCN,
    zoneOther,
    zoneMain,
    postcode,
    borough,
    completion,
    bed0,
    bed1,
    bed2,
    bed3,
    bed4,
    bed5,
    bed6,
    station,
    distance,
  } = props.obj;
  const lang = useSelector(getLang);
  const isMobile = useSelector(getIsMobile);

  const imageId = `NV${String(id).padStart(4, "0").slice(-4)}.jpg`;
  const imgUrl = require("../../../../assets/media/developmentImages/" +
    imageId);

  return (
    <>
      <img
        alt=""
        className={`
          ${props.customiseImg ? (isMobile ? "w-[60px] h-auto flex-grow object-cover rounded-l-lg" : "w-[80px] h-full object-cover rounded-l-lg") : "w-full grid-dev-img rounded-l-lg"}
        ${props.inGrid ? "w-[35vw]" : ""}
          `}
        // className={`
        //   ${isMobile ? (props.customiseImg ? "w-[50px] h-auto flex-grow object-cover rounded-l-lg" : "") : (props.customiseImg
        //     ? "w-full h-40 object-cover rounded-t-lg"
        //     : "grid-dev-img rounded-l-lg")}`}
        src={imgUrl}
      />
      <div className={`info-dev-content text-[1rem] p-6 ${isMobile ? "p-3" : "p-6"} text-sm`}>
        <div className={`${props.inGrid ? "text-[1.75rem]" : "text-[1.25rem]"} font-bold leading-tight text-secondary-dark ${isMobile ? " mb-2" : " mb-6"}`} >
          <h2 className="text-inherit-size flex items-center gap-2">
            {name}&nbsp;
            {house && <IncludeHouseIcon width="18" height="18" />}
          </h2>
          {/* {<p className={`${lang && "hidden"} cn`}><span>{nameCN}</span></p>} */}
          {<p className={`${lang && "hidden"} cn text-xl font-normal text-gray-600`}><span>{nameCN}</span></p>}
        </div>
        <p className="mb-3 text-inherit-size">
          <span className="text-inherit-size">
            <b className="text-inherit-size">
              {lang ? "Estimated completion: " : "预计交房时间："}
            </b>
          </span>
          <span className="text-inherit-size">
            {lang
              ? completion
              : completion === "Completed"
                ? "现房"
                : completion}
          </span>
        </p>
        <div
          className={`flex text-inherit-size flex-col mobile:flex-row gap-8 sm:gap-5 leading-tight`}
        >
          <ul className="price-list whitespace-nowrap text-inherit-size ">
            <li className="text-inherit-size">
              <b className="text-inherit-size">{lang ? "Price from:" : "价格从："}</b>
            </li>
            <div className="text-inherit-size">
              {bed0 && (
                <li className="text-inherit-size">
                  <b className="text-inherit-size">
                    {lang ? "Studio: " : "开间："}
                  </b>
                  {renderNum(bed0)}
                </li>
              )}
              {bed1 && (
                <li className="text-inherit-size">
                  <b className="text-inherit-size">
                    {lang ? "1 bed: " : "一室："}
                  </b>
                  {renderNum(bed1)}
                </li>
              )}
              {bed2 && (
                <li className="text-inherit-size">
                  <b className="text-inherit-size">
                    {lang ? "2 bed: " : "两室："}
                  </b>
                  {renderNum(bed2)}
                </li>
              )}
              {bed3 && (
                <li className="text-inherit-size">
                  <b className="text-inherit-size">
                    {lang ? "3 bed: " : "三室："}
                  </b>
                  {renderNum(bed3)}
                </li>
              )}
              {bed4 && (
                <li className="text-inherit-size">
                  <b className="text-inherit-size">
                    {lang ? "4 bed: " : "四室："}
                  </b>
                  {renderNum(bed4)}
                </li>
              )}
              {bed5 && (
                <li className="text-inherit-size">
                  <b className="text-inherit-size">
                    {lang ? "5 bed: " : "五室："}
                  </b>
                  {renderNum(bed5)}
                </li>
              )}
              {bed6 && (
                <li className="text-inherit-size">
                  <b className="text-inherit-size">
                    {lang ? "6 bed: " : "六室："}
                  </b>
                  {renderNum(bed6)}
                </li>
              )}
              {(!bed0 && !bed1 && !bed2 && !bed3 && !bed4 && !bed5 && !bed6) &&
                <li className="text-inherit-size">TBC</li>
              }
            </div>

          </ul>
          <ul className="text-inherit-size">
            <li className="text-inherit-size">
              <b className="text-inherit-size">
                {lang ? "Zone: " : "地铁区："}
              </b>
              {zoneMain <= 6 ? zoneMain : "N/A"}{" "}
              {zoneOther && zoneOther <= 6 && `/ ${zoneOther}`}
            </li>
            <li className="text-inherit-size">
              <b className="text-inherit-size">
                {lang ? "Postcode: " : "邮编："}
              </b>
              {postcode ? postcode : "N/A"}
            </li>
            <li className="text-inherit-size">
              <b className="text-inherit-size">
                {lang ? "Borough: " : "区域："}
              </b>
              {borough}
            </li>
            <li className="text-inherit-size">
              <b className="text-inherit-size">
                {lang ? "Station: " : "最近车站："}
              </b>
              {station} ({distance}km)
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
