import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./InfoCollection.module.scss";
import { NavLink } from "react-router-dom";
import { preparePDFData } from "../../util/PDFRelated/printMapPDF";
import { useDispatch, useSelector } from "react-redux";
import {
  checkFilterChanged,
  getFilters,
  getKeywordFilteredDevelopmentIds,
} from "../../features/developments/developmentsSlice";
import { uploadClientInfo } from "../../api/user";
import { getPdfStatus, resetTransform, setPdfStatus } from "../../features/states/statesSlice";
import Spinner from "../../components/Spinner/Spinner";
import { getLang } from "../../features/app/UserRelatedSlice";
import { getIsRealMobile } from "../../features/app/UIRelatedSlice";

export default function InfoCollection() {
  const pdfGenerationDivRef = useRef<HTMLDivElement | null>(null);
  const changedFilters = useSelector(checkFilterChanged);
  const filterRules = useSelector(getFilters);
  const abortControllerRef = useRef<AbortController | null>(null);

  const [info, setInfo] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    wechat: "",
  });

  // const { pdfStatus, setPdfStatus } = useContext(SalesContext) as ISalesContext;
  const dispatch = useDispatch();
  const reduxPdfStatus = useSelector(getPdfStatus);
  const [isHidden, setIsHidden] = useState(reduxPdfStatus === "hide");
  const devCount = useSelector(getKeywordFilteredDevelopmentIds);

  const lang = useSelector(getLang);
  const isRealMobile = useSelector(getIsRealMobile);
  const [pdfFile, setPdfFile] = useState<Blob | null>(null);
  const [error, setError] = useState("");
  const [generatedMobile, setGeneratedMobile] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDownloadAllowed, setIsDownloadAllowed] = useState(false);
  const filteredChanged = useSelector(checkFilterChanged);

  const workerRef = useRef<Worker | null>(null);

  const date = new Date();
  const fileName = `NVRE伦敦新开发楼盘${filteredChanged ? "筛选" : "汇总"} ${date.getFullYear()}年${date.getMonth() + 1
    }月`;



  // Function to update both local and Redux state
  const updatePdfStatus = useCallback(
    (
      status: "idle" | "preparing" | "generating" | "ready" | "error" | "hide"
    ) => {
      setIsHidden(status === "hide");
      dispatch(setPdfStatus(status));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      localStorage.getItem("download") &&
      localStorage.getItem("download") === "true"
    )
      return;
    const { title, firstName, lastName, email, mobile, wechat } = info;
    const isNameFilled = title && firstName && lastName;
    const contactMethodsFilled =
      [email, mobile, wechat].filter(Boolean).length >= 2;

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEmailValid = !email || emailPattern.test(email);
    if (isNameFilled && contactMethodsFilled && isEmailValid) {
      setIsDownloadAllowed(true);
    } else {
      setIsDownloadAllowed(false);
    }
  }, [info]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleSubmitAndDownload = useCallback(
    async (e: React.FormEvent, isMobile: boolean) => {
      e.preventDefault();

      if (!isDownloadAllowed) {
        setError(
          lang ? "Please fill all required fields" : "请填写所有必填字段"
        );
        return;
      }

      dispatch(resetTransform());

      if (
        !(
          localStorage.getItem("download") &&
          localStorage.getItem("download") === "true"
        )
      ) {
        const user = _registerUserInfo();
        if (!user) {
          setError(lang ? "Failed to register user info" : "注册用户信息失败");
          return;
        }

        localStorage.setItem("download", "true");
      }

      setGeneratedMobile(isMobile);
      abortControllerRef.current = new AbortController();
      setProgress(20);
      updatePdfStatus("preparing");

      try {
        // const tubeViewElement = document.getElementById("print-tube-view");
        // if (tubeViewElement) {
        //   const transformWrapper = tubeViewElement.querySelector('.react-transform-wrapper');
        //   if (transformWrapper) {
        //     // Reset transform scale to 1
        //     (transformWrapper as HTMLElement).style.transform = 'translate(0px, 0px) scale(1)';
        //   }
        // }

        const pdfData = await preparePDFData(
          {
            "tube-view": document.getElementById("print-tube-view"),
            "list-view": document.getElementById("print-list-view"),
          },
          filterRules,
          changedFilters,
          isMobile,
          (progress: any) => {
            setProgress(progress * 0.8);
          },
          abortControllerRef.current.signal
        );

        if (!pdfData) {
          updatePdfStatus("hide");
          setProgress(0);
          return;
        }

        updatePdfStatus("generating");

        const worker = new Worker(
          new URL("../../util/PDFRelated/pdfWorker.ts", import.meta.url)
        );
        workerRef.current = worker;


        worker.postMessage({
          action: "generatePDF",
          pdfData: { ...pdfData, devCount: devCount.length, filteredChanged },
        });

        worker.onmessage = (event) => {
          if (event.data.status === "complete") {
            setPdfFile(event.data.blob);
            updatePdfStatus("ready");
            setProgress(100);
          } else if (event.data.status === "error") {
            updatePdfStatus("error");
            console.error("Error generating PDF:", event.data.error);
          } else if (event.data.status === "progress") {
            setProgress(80 + event.data.progress * 0.2);
          } else if (event.data.status === "aborted") {
            updatePdfStatus("hide");
          }

          if (event.data.status !== "progress") {
            worker.terminate();
          }
        };

        // Set up abort functionality
        const abortListener = () => {
          worker.terminate();
          dispatch(setPdfStatus("hide"));
        };

        abortControllerRef.current.signal.addEventListener(
          "abort",
          abortListener
        );

        // Clean up function
        return () => {
          if (abortControllerRef.current) {
            abortControllerRef.current.signal.removeEventListener(
              "abort",
              abortListener
            );
          }
        };
      } catch (error) {
        if (error instanceof DOMException && error.name === "AbortError") {
          updatePdfStatus("hide");
        } else {
          console.error("Error preparing PDF data:", error);
          updatePdfStatus("error");
        }
      }

      function _registerUserInfo() {
        const { title, firstName, lastName, email, mobile, wechat } = info;
        if (
          !title ||
          !firstName ||
          !lastName ||
          (email ? 1 : 0) + (mobile ? 1 : 0) + (wechat ? 1 : 0) < 2
        ) {
          setError(lang ? "Complete all required fields" : "请填写所有必填字段");
          return;
        }
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (email && !emailPattern.test(email)) {
          setError(
            lang ? "Please enter a valid email address" : "请输入有效的电子邮件地址"
          );
          return;
        }

        const user = uploadClientInfo(info);
        return user;
      }
    },
    [
      isDownloadAllowed,
      lang,
      dispatch,
      filterRules,
      changedFilters,
      updatePdfStatus,
      devCount.length,
      filteredChanged,
      info
    ]
  );

  useEffect(() => {
    updatePdfStatus("hide");
    const downloadStatus = localStorage.getItem("download");
    setIsDownloadAllowed(downloadStatus === "true");
  }, [updatePdfStatus]);

  // Synchronize local state with Redux state
  useEffect(() => {
    setIsHidden(reduxPdfStatus === "hide");
  }, [reduxPdfStatus]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, []);



  const handleCancelGenerating = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      // Immediately update the UI
      setIsHidden(true);
      setProgress(0);

      // Then update Redux and abort the operation
      updatePdfStatus("hide");
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
    },
    [updatePdfStatus]
  );

  // CLICK OUTSIDE TO CLOSE WINDOW //
  /*
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (pdfGenerationDivRef.current && !pdfGenerationDivRef.current.contains(event.target)) {
                setPdfStatus("hide");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
 
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    */

  if (isHidden) return null;
  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen bg-black/20 z-[99999]"></div>
      <div
        ref={pdfGenerationDivRef}
        className={`fixed ${isRealMobile
          ? "w-[95vw] h-[30vh] overflow-y-scroll left-[2.5vw] top-[20vh]"
          : "top-[30vh] left-[50%] -translate-x-[50%]"
          } z-[999999] bg-white p-10 shadow-xl border rounded-lg overflow-y-scroll`}
        style={{ maxHeight: isRealMobile ? "auto" : "calc(100vh - 120px)" }}
      >
        <p
          className="absolute right-6 top-5 text-xl text-gray-400 hover:text-gray-800 hover:cursor-pointer"
          onClick={() => {
            dispatch(setPdfStatus("hide"));
          }}
        >
          ✕
        </p>
        <div
          className={`flex flex-col items-center gap-10 xs:mt-10 w-[400px] sm:w-[528px]`}
        >
          {isRealMobile && (
            <div>
              <p className="text-main font-bold text-xl">
                {lang ? "Thank you for your interest!" : "感谢您的关注！"}
              </p>
              {lang ? (
                <p className="mt-10">
                  If you would like to download PDF, please use{" "}
                  <span className="text-secondary-dark">Chrome</span>,{" "}
                  <span className="text-secondary-dark">Edge</span>, or{" "}
                  <span className="text-secondary-dark">Firefox</span> on{" "}
                  <span className="font-bold text-main">desktop</span>, and
                  avoid <span className="text-secondary-dark">Safari</span> if
                  possible.
                </p>
              ) : (
                <p className="mt-10">
                  如果您想下载 PDF，请使用{" "}
                  <span className="text-secondary-dark">Chrome</span>、
                  <span className="text-secondary-dark">Edge</span> 或{" "}
                  <span className="text-secondary-dark">Firefox</span> 浏览器在
                  <span className="font-bold text-main">电脑端</span>
                  ，尽量避免使用{" "}
                  <span className="text-secondary-dark">Safari</span>。
                </p>
              )}
            </div>
          )}
          {!isRealMobile && reduxPdfStatus === "idle" && (
            <form
              className="flex flex-col items-start xs:items-center"
              onFocus={() => {
                setError("");
              }}
            >
              <p className="text-main font-bold text-xl">
                {lang ? "Thank you for your interest!" : "感谢您的关注！"}
              </p>
              {!(
                localStorage.getItem("download") &&
                localStorage.getItem("download") === "true"
              ) && (
                  <>
                    <p className="mb-20 mt-4 opacity-60">
                      {lang
                        ? "Please kindly provide us your information, to download your search result as PDF!"
                        : "请提供您的信息，以便下载您的搜索结果为PDF！"}
                    </p>
                    <div className={`flex flex-col gap-2 w-full`}>
                      <p className="self-start font-bold text-secondary-dark text-lg">
                        {lang ? "Name*" : "姓名*"}
                      </p>
                      <div
                        className={`${styles.nameInputs} flex flex-col xs:flex-row gap-2 mt-2 w-full`}
                      >
                        <label className="bg-white">
                          <span>{lang ? "Title" : "称呼"}</span>
                          <select
                            name="title"
                            className="w-32 bg-white"
                            value={info.title}
                            onChange={handleInputChange}
                            required
                          >
                            {lang ? (
                              <>
                                <option value="">Select</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                                <option value="Miss">Miss</option>
                              </>
                            ) : (
                              <>
                                <option value="">请选择</option>
                                <option value="先生">先生</option>
                                <option value="女士">女士</option>
                              </>
                            )}
                          </select>
                        </label>
                        <label className={`flex-grow bg-white`}>
                          <span>{lang ? "First Name" : ""}</span>
                          <input
                            name="firstName"
                            className="w-full bg-white"
                            type="text"
                            value={info.firstName}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                        <label className={`flex-grow bg-white`}>
                          <span>{lang ? "Last Name" : ""}</span>
                          <input
                            name="lastName"
                            className="w-full bg-white"
                            type="text"
                            value={info.lastName}
                            onChange={handleInputChange}
                            required
                          />
                        </label>
                      </div>
                      <p className="mt-10 mb-2 ">
                        <span className="font-bold text-secondary-dark text-lg">
                          {lang ? "Contact*" : "联系方式*"}
                        </span>
                        <span className="opacity-50">
                          &nbsp;&nbsp;
                          {lang
                            ? "(please provide at least two contact method)"
                            : "(请至少提供两种联系方式)"}
                        </span>
                      </p>
                      <div
                        className={`${styles.contactInputs} flex flex-col gap-2`}
                      >
                        <label>
                          <span>{lang ? "Email" : "邮箱"}</span>
                          <input
                            name="email"
                            className="input input-bordered bg-white"
                            type="text"
                            value={info.email}
                            onChange={handleInputChange}
                          />
                        </label>
                        <label>
                          <span>
                            {lang ? "Mobile / WhatsApp" : "手机 / WhatsApp"}
                          </span>
                          <input
                            name="mobile"
                            className="input input-bordered bg-white"
                            type="text"
                            value={info.mobile}
                            onChange={handleInputChange}
                          />
                        </label>
                        <label className={`${!lang && "-order-1"}`}>
                          <span>{lang ? "WeChat ID" : "微信"}</span>
                          <input
                            name="wechat"
                            className="input input-bordered bg-white"
                            type="text"
                            value={info.wechat}
                            onChange={handleInputChange}
                          />
                        </label>
                      </div>
                    </div>
                  </>
                )}
              <p className="text-red-800 mt-10">
                {error ? error : <span>&nbsp;</span>}
              </p>
              <div className="flex flex-col w-full relative items-center  border-[0.5px] border-secondary-dark/20 p-3 rounded-lg">
                <p className="text-secondary-dark/20 absolute -top-4 left-4 bg-white px-2">
                  {lang ? "Generate PDF" : "生成PDF"}
                </p>
                <div className="flex flex-col xs:flex-row gap-2 w-full">
                  <button
                    className={`bg-main w-full text-white  py-1 px-3 rounded-md ${!isDownloadAllowed
                      ? "opacity-20"
                      : "hover:bg-secondary-dark"
                      }`}
                    onClick={(e) => {
                      handleSubmitAndDownload(e, false);
                    }}
                    disabled={!isDownloadAllowed}
                  >
                    {lang ? "DESKTOP" : "电脑版"}
                  </button>
                  <button
                    className={`bg-main w-full text-white  py-1 px-3 rounded-md ${!isDownloadAllowed
                      ? "opacity-20"
                      : "hover:bg-secondary-dark"
                      }`}
                    onClick={(e) => {
                      handleSubmitAndDownload(e, true);
                    }}
                    disabled={!isDownloadAllowed}
                  >
                    {lang ? "MOBILE" : "手机版"}
                  </button>
                </div>
              </div>
              {!isDownloadAllowed && (
                <p className="text-red-800 text-xs mt-2">
                  {lang
                    ? "Please fill in the form to enable PDF download."
                    : "请填写表单以启用PDF下载。"}
                </p>
              )}
              <p className="text-gray-600 text-sm mt-2">
                {lang
                  ? "By generating PDF, you are accepting to our"
                  : "通过生成PDF，您接受我们的"}{" "}
                <NavLink to="" className="underline text-inherit-size">
                  {lang ? "Cookies policies" : "Cookie 政策"}
                </NavLink>{" "}
                {lang ? "and" : "和"}{" "}
                <NavLink to="" className="underline text-inherit-size">
                  {lang ? "Privacy policies" : "隐私政策"}
                </NavLink>
                {lang ? "." : "。"}
              </p>
            </form>
          )}
          {!isRealMobile &&
            // (reduxPdfStatus === "idle" ||
            (reduxPdfStatus === "preparing" ||
              reduxPdfStatus === "generating") && (
              <>
                <div>
                  {lang
                    ? "Creating PDF... Please do not refresh the page."
                    : "正在创建 PDF... 请不要刷新页面。"}
                </div>
                {/* <div className={styles.progressContainer}>
                  <div
                    className={`${styles.progressBar}`}
                  >
                    <div className="bg-black absolute top-0 left-0" style={{ width: `${progress}%` }}></div>
                  </div>
                </div> */}
                <Spinner />
                {/* <div className={styles.progressContainer}> */}
                <progress
                  // className={`w-full ${styles.progressBar}`}
                  className={`progress w-full`}
                  // className={`progress w-full ${styles.progressBar}`}
                  value={progress}
                  max="100"
                ></progress>
                {/* <div
                    className={`${styles.progressBar}`}
                  ></div> */}
                {/* </div> */}
                <button
                  className="text-main ml-10"
                  type="button"
                  onClick={handleCancelGenerating}
                >
                  {lang ? "Cancel" : "取消"}
                </button>
              </>
            )}
          {!isRealMobile && reduxPdfStatus === "ready" && (
            <div className="bg-white p-1 flex flex-col gap-6">
              <div>{lang ? "Your PDF is ready!" : "您的PDF已经准备好了！"}</div>
              <button
                className="bg-main text-white w-full p-3 rounded-md font-bold hover:bg-secondary-dark"
                type="button"
                onClick={() => {
                  if (pdfFile) {
                    // const url = URL.createObjectURL(pdfFile);
                    // window.open(url, '_blank');
                    // setPdfStatus("hide");
                    const url = URL.createObjectURL(pdfFile);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download =
                      fileName + (generatedMobile ? " 手机版本" : "") + ".pdf";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(setPdfStatus("hide"));
                  }
                }}
              >
                {lang ? "DOWNLOAD PDF" : "下载 PDF"}
              </button>
            </div>
          )}
          {!isRealMobile && reduxPdfStatus === "error" && (
            <div className="flex flex-col items-center gap-6">
              <p>{lang ? "Failed to generate PDF" : "生成PDF失败"}</p>
              <button
                className="bg-main text-white w-full p-3 rounded-md font-bold hover:bg-secondary-dark"
                type="button"
                onClick={() => {
                  dispatch(setPdfStatus("idle"));
                }}
              >
                {lang ? "Try again" : "再试一次"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
