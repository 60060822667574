import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UIRelatedState {
    isWidthHideFilter: boolean;
    showFilter: boolean;
    editMode: boolean;
    showContactPopup: boolean;
    showPermissionPopup: boolean;
    showActionPopup: boolean;
    isMobile: boolean;
    isRealMobile: boolean;
    isSmallScreen: boolean;
    showFilterCarriedOverNotification: boolean;
}

const initialState: UIRelatedState = {
    isWidthHideFilter: false,
    showFilter: false,
    editMode: false,
    showContactPopup: false,
    showPermissionPopup: false,
    showActionPopup: false,
    isMobile: true,
    isRealMobile: true,
    isSmallScreen: true,
    showFilterCarriedOverNotification: false,
};

export const uiRelatedSlice = createSlice({
    name: "uirelated",
    initialState,
    reducers: {
        setIsWidthHideFilter: (state, action: PayloadAction<boolean>) => {
            state.isWidthHideFilter = action.payload;
        },
        setShowFilter: (state, action: PayloadAction<boolean>) => {
            state.showFilter = action.payload;
        },
        setEditMode: (state, action: PayloadAction<boolean>) => {
            state.editMode = action.payload;
        },
        setShowContactPopup: (state, action: PayloadAction<boolean>) => {
            state.showContactPopup = action.payload;
        },
        setShowPermissionPopup: (state, action: PayloadAction<boolean>) => {
            state.showPermissionPopup = action.payload;
        },
        setShowActionPopup: (state, action: PayloadAction<boolean>) => {
            state.showActionPopup = action.payload;
        },
        setIsMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
        setIsRealMobile: (state, action: PayloadAction<boolean>) => {
            console.log("setIsRealMobile", action.payload);
            state.isRealMobile = action.payload;
        },
        setIsSmallScreen: (state, action: PayloadAction<boolean>) => {
            state.isSmallScreen = action.payload;
        },
        setShowFilterCarriedOverNotification: (state, action: PayloadAction<boolean>) => {
            state.showFilterCarriedOverNotification = action.payload;
        },
    },
});

// SELECTORS
export const getIsWidthHideFilter = (state: any) => state.uiRelated.isWidthHideFilter;
export const getShowFilter = (state: any) => state.uiRelated.showFilter;
export const getEditMode = (state: any) => state.uiRelated.editMode;
export const getShowContactPopup = (state: any) => state.uiRelated.showContactPopup;
export const getShowPermissionPopup = (state: any) => state.uiRelated.showPermissionPopup;
export const getShowActionPopup = (state: any) => state.uiRelated.showActionPopup;
export const getIsMobile = (state: any) => state.uiRelated.isMobile;
export const getIsRealMobile = (state: any) => state.uiRelated.isRealMobile;
export const getIsSmallScreen = (state: any) => state.uiRelated.isSmallScreen;
export const getShowFilterCarriedOverNotification = (state: any) => state.uiRelated.showFilterCarriedOverNotification;

export const {
    setIsWidthHideFilter,
    setShowFilter,
    setEditMode,
    setShowContactPopup,
    setShowPermissionPopup,
    setShowActionPopup,
    setIsMobile,
    setIsRealMobile,
    setIsSmallScreen,
    setShowFilterCarriedOverNotification,
} = uiRelatedSlice.actions;

export default uiRelatedSlice.reducer;