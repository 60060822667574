import React, { useCallback, useEffect, useRef, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeDevelopment,
  getKeywordFilteredDevelopmentIds,
  Development,
} from "../../../features/developments/developmentsSlice";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapView.scss";
import ReactDOMServer from "react-dom/server";
import Popup from "../Components/Common/Popup";
import { IncludeHouseIcon } from "../../../assets/assetExport";
import { getAllDevelopments } from "../../../features/developments/developmentsSlice";
import { getSearchName } from "../../../features/app/UserRelatedSlice";
import { getEditMode, getIsMobile, getIsRealMobile } from "../../../features/app/UIRelatedSlice";
import { SpinnerCircles } from "../../../util/Spinners/Spinners";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3FpaSIsImEiOiJja3kzYXlnNHkwMGNpMnNwN2RtMGhxMjI4In0.RpbUFrXuGm4d_QjFNIYo9A";

const MapView = (props: any) => {
  const searchName = useSelector(getSearchName);
  const isRealMobile = useSelector(getIsRealMobile);
  const isMobile = useSelector(getIsMobile);
  const dispatch = useDispatch();
  const allDevelopments = useSelector(getAllDevelopments);
  const keywordFilteredDevelopmentIds = useSelector(getKeywordFilteredDevelopmentIds);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<{ [key: string]: mapboxgl.Marker; }>({});
  const popupsRef = useRef<{ [key: string]: mapboxgl.Popup; }>({});

  const editMode = useSelector(getEditMode);
  const [selectedDevelopment, setSelectedDevelopment] = useState<Development | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isInitializedRef = useRef(false);
  const { isVisible } = props;

  // useEffect(() => {
  //   if (mapRef.current) {
  //     mapRef.current.on('load', () => {
  //       console.log('Map loaded');
  //       setMapLoaded(true);
  //     });
  //   }
  // }, []);


  const removeAllMarkersAndPopupsOnMap = useCallback(() => {
    if (!isRealMobile) {
      Object.keys(popupsRef.current).forEach((id) => {
        popupsRef.current[id].remove();
        delete popupsRef.current[id];
      });
    }

    Object.keys(markersRef.current).forEach((id) => {
      markersRef.current[id].remove();
      delete markersRef.current[id];
    });

    setSelectedDevelopment(null);
  }, [isRealMobile, setSelectedDevelopment]);

  const handleRemoveDevelopment = useCallback((event: any) => {
    setSelectedDevelopment(event.target.id);
    if (!event.target.matches(".marker-remove")) return;

    const buttonId = event.target.id;

    if (markersRef.current[buttonId]) {
      markersRef.current[buttonId].remove();
      delete markersRef.current[buttonId];
    }

    dispatch(removeDevelopment(+buttonId));

    event.stopPropagation();
  }, [dispatch]);


  const loadDataOnMap = useCallback(() => {
    let markersToLoad = keywordFilteredDevelopmentIds.length;

    keywordFilteredDevelopmentIds.forEach((id: any) => {
      if (allDevelopments[id] && !markersRef.current[id]) {
        const development = allDevelopments[id];

        const jsxString = ReactDOMServer.renderToStaticMarkup(
          <IncludeHouseIcon width="15" height="15" />
        );
        const domParser = new DOMParser();
        const svgElement = domParser.parseFromString(
          jsxString,
          "image/svg+xml"
        ).documentElement;
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const svgAsDataURL =
          "data:image/svg+xml," + encodeURIComponent(svgString);

        enum PurpleShades {
          Base99 = "#edeaf0",
          Base7 = "#edeaf0",
          Base6 = "#edeaf0",
          Base5 = "#edeaf0",
          Base4 = "#edeaf0",
          Base3 = "#edeaf0",
          Base2 = "#edeaf0",
          Base1 = "#edeaf0",
        }

        const color =
          PurpleShades[
          ("Base" + development.zoneMain) as keyof typeof PurpleShades
          ];

        const MyMarker = (
          <div
            id={String(development.id)}
            className={`border w-[100px] h-[30px] whitespace-nowrap border-secondary-dark/10 shadow-md rounded-lg font-normal text-secondary-dark flex flex-col justify-center gap-1 p-1 px-2 hover:cursor-pointer`}
            style={{ backgroundColor: color }}
          >
            <div id={String(development.id)} className="absolute right-1 top-1 inset-0 h-[80%] w-[30%] ml-auto rounded-lg bg-gradient-to-r from-transparent to-tertiary-light pointer-events-none"></div>
            <p id={String(development.id)} className="flex gap-2 items-center pr-2 overflow-hidden">
              {development.house && (
                <img id={String(development.id)} className="opacity-60" src={svgAsDataURL} alt="House Icon" />
              )}
              {development.name}
            </p>
            <button
              id={String(development.id)}
              className="marker-remove absolute top-[-10px] right-[-10px] text-lg bg-secondary-light p-5 rounded-full w-5 h-5 items-center justify-center text-white shadow-md"
            >
              ✕
            </button>
          </div>
        );

        const markerHtml = ReactDOMServer.renderToString(MyMarker);

        const markerEl = document.createElement("div");
        markerEl.innerHTML = markerHtml;
        markerEl.addEventListener("click", handleRemoveDevelopment);

        const marker = new mapboxgl.Marker(markerEl)
          .setLngLat([development.longitude, development.latitude])
          .addTo(mapRef.current || new mapboxgl.Map({ container: 'map-container' }));

        markersRef.current[development.id] = marker;

        markersToLoad -= 1;
        if (markersToLoad === 0) {
          setIsLoading(false);
        }
      }
    });

    if (markersToLoad === 0) {
      setIsLoading(false);
    }
  }, [keywordFilteredDevelopmentIds, allDevelopments, handleRemoveDevelopment]);


  useEffect(() => {
    if (!mapRef.current && !isInitializedRef.current) {
      console.log("init map");
      const mapContainer = document.getElementById("map");
      if (mapContainer) {
        mapContainer.innerHTML = "";
      }

      mapRef.current = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v12",
        center: [-0.0999, 51.5085224],
        zoom: 12,
      });

      // mapRef.current.on('load', () => {
      //   setIsLoading(false);
      // });

      mapRef.current.on('error', (e) => {
        console.error('Mapbox error:', e);
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");
      mapRef.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
        "top-left"
      );

      isInitializedRef.current = true;
    }

    return () => {
      // Only clean up markers, not the map instance
      Object.values(markersRef.current).forEach(marker => marker.remove());
      markersRef.current = {};
    };
  }, []);

  // Update markers only when visible
  useEffect(() => {
    if (isVisible && mapRef.current) {
      removeAllMarkersAndPopupsOnMap();
      loadDataOnMap();
    }
  }, [isVisible, searchName, keywordFilteredDevelopmentIds, loadDataOnMap, removeAllMarkersAndPopupsOnMap]);

  // Resize map when becoming visible
  useEffect(() => {
    if (isVisible && mapRef.current) {
      mapRef.current.resize();
    }
  }, [isVisible]);



  // useEffect(() => {
  //   if (!mapShowing) return;
  //   removeAllMarkersAndPopupsOnMap();
  //   loadDataOnMap();
  // }, [searchName, mapShowing, keywordFilteredDevelopmentIds, dispatch, loadDataOnMap, removeAllMarkersAndPopupsOnMap]);

  useEffect(() => {
    removeAllMarkersAndPopupsOnMap();
    loadDataOnMap();
  }, [searchName, keywordFilteredDevelopmentIds, dispatch, loadDataOnMap, removeAllMarkersAndPopupsOnMap]);

  return (
    <>
      <div className="relative h-full w-full">
        {isLoading && (
          <div className={`${props.rounded ? "rounded-xl" : ""} absolute inset-0 flex items-center justify-center bg-secondary-[#ebe7e4] z-50`}>
            <SpinnerCircles />
          </div>
        )}
        <div
          id="map"
          className={`h-full ${props.rounded ? "rounded-xl" : ""} ${props.onHomePage ? "mapboxgl-map hide" : (editMode ? "show mapboxgl-map" : "hide mapboxgl-map")}`}
        ></div>

      </div>
      {
        selectedDevelopment && isVisible && (
          <div className={`${props.isMapViewContainerAboveBottomScreen ? "absolute" : "fixed"} ${props.onHomePage ? "left-[50%] -translate-x-[50%]" : " left-[2.5vw]"} ${isMobile ? "w-[95vw]" : "w-auto"} bottom-20  z-50`}>
            <Popup
              obj={allDevelopments[+selectedDevelopment]}
              setCurrentObj={() => setSelectedDevelopment(null)}
              isMap={true}
            />
          </div>
        )
      }
    </>
  );
};

export default MapView;
