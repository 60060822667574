import React, { useEffect } from "react";
import SalePage from "../pages/Sale/SalePage";
import HomePage from "../pages/Home/HomePage";
import Header from "../layouts/Header/Header";
import Footer from "../layouts/Footer/Footer";
import Rent from "../pages/Rent/Rent";
import About from "../pages/About/About";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import StampDutyPage from "../pages/Services/StampDutyPage/StampDutyPage";
import RentalYieldPage from "../pages/Services/YieldPage/YieldPage";
import RentEstimatePage from "../pages/Services/RentEstimatePage/RentEstimatePage";
import MortgagePage from "../pages/Services/MorgagePage/MortgagePage";
import ToolMenu from "../items/ToolMenu/ToolMenu";
import ClientInfo from "../pages/Admin/Admin";
import ContactUs from "../items/ContactPopup/ContactPopup";
import PermissionPopup from "../items/PermissionPopup/PermissionPopup";
import ActionPopup from "../items/ActionPopup/ActionPopup";
import { useSelector } from "react-redux";
import { getLayoutHeaderHeight } from "../features/layouts/layoutsSlice";
import NotFoundPage from "../pages/Exceptions/NotFoundPage";
import { getAllDevelopmentsStatus } from "../features/developments/developmentsSlice";
import FloatingNotification from "../items/FloatingNotification/FloatingNotification";
import { Helmet } from "react-helmet";
import { getShowActionPopup, getShowContactPopup, getShowPermissionPopup } from "../features/app/UIRelatedSlice";
import { getPermission } from "../features/app/UserRelatedSlice";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const AppRoutes = () => {

  const showContactPopup = useSelector(getShowContactPopup);
  const showPermissionPopup = useSelector(getShowPermissionPopup);
  const showActionPopup = useSelector(getShowActionPopup);
  const permission = useSelector(getPermission);
  const headerHeight = useSelector(getLayoutHeaderHeight);
  const loadError = useSelector(getAllDevelopmentsStatus);

  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <main
        className={`relative transition-all duration-500 ease-in-out z-[999] bg-white`}
        // className={`${headerHeight && "mt-[" + headerHeight + "px]"} relative transition-all duration-500 ease-in-out z-[999]`}
        style={{
          minHeight: `calc(100vh - ${headerHeight}px)`,
          paddingTop: headerHeight + "px",
          WebkitMinHeight: `calc(100vh - ${headerHeight}px)`
        }}
      >
        <Routes>
          <Route path="/lettings" element={
            <>
              <Helmet>
                <title>Lettings | NVRE</title>
                <meta
                  name="description"
                  content="Use our lettings service."
                />
                <meta
                  name="keywords"
                  content="residential property, real estate, london, lettings, lettings service"
                />
              </Helmet>
              <Rent />
            </>
          } />
          <Route
            path="/services/stamp-duty-calculator"
            element={
              <>
                <Helmet>
                  <title>Stamp Duty Calculator | NVRE</title>
                  <meta
                    name="description"
                    content="Use our stamp duty calculator."
                  />
                  <meta
                    name="keywords"
                    content="residential property, real estate, london, stamp duty calculator, stamp duty"
                  />
                </Helmet>
                <StampDutyPage />
              </>
            }
          />
          <Route path="/services/rental-yield" element={
            <>
              <Helmet>
                <title>Rental Yield | NVRE</title>
                <meta
                  name="description"
                  content="Use our rental yield to calculate your return on investment. "
                />
                <meta
                  name="keywords"
                  content="residential property, real estate, london, rental yield, return on investment"
                />
              </Helmet>
              <RentalYieldPage />
            </>}
          />
          <Route
            path="/services/rent-estimate"
            element={
              <>
                <Helmet>
                  <title>Rent Estimate | NVRE</title>
                  <meta
                    name="description"
                    content="Use our rent estimator for your property. "
                  />
                  <meta
                    name="keywords"
                    content="residential property, real estate, london, rent estimate, rent"
                  />
                </Helmet>
                <RentEstimatePage />
              </>
            }
          />
          <Route
            path="/services/mortgage-calculator"
            element={
              <>
                <Helmet>
                  <title>Mortgage Calculator | NVRE</title>
                  <meta
                    name="description"
                    content="Use our mortgage calculator for your property."
                  />
                  <meta
                    name="keywords"
                    content="residential property, real estate, london, mortgage calculator"
                  />
                </Helmet>
                <MortgagePage />
              </>
            }
          />
          <Route path="/about" element={
            <>
              <Helmet>
                <title>About | NVRE</title>
                <meta
                  name="description"
                  content="Learn more about NVRE."
                />
                <meta
                  name="keywords"
                  content="residential property, real estate, london, about, about us"
                />
              </Helmet>
              <About />
            </>
          } />
          <Route path="/property" element={
            <>
              <Helmet>
                <title>Property | NVRE</title>
                <meta
                  name="description"
                  content="Find your dream home with NVRE."
                />
                <meta
                  name="keywords"
                  content="residential property, real estate, london, property, dream home"
                />
              </Helmet>
              <SalePage />
            </>
          } />
          {/* <Route path="/login" element={<RegisterLoginPage />} /> */}
          {permission > 4 &&
            <Route path="/client-info" element={
              <>
                <Helmet>
                  <title>Client Info | NVRE</title>
                  <meta
                    name="description"
                    content="Manage your clients with NVRE."
                  />
                </Helmet>
                <ClientInfo />
              </>
            } />
          }
          <Route path="/" element={
            <>
              <Helmet>
                <title>Home | NVRE</title>
                <meta
                  name="description"
                  content="Welcome to NVRE."
                />
                <meta
                  name="description"
                  content="Find your dream home with NVRE. We provide a wide range of services to help you buy, sell, and manage your property. We have tools to help you calculate your rental yield, stamp duty, and mortgage. We have the most comprehensive property database in London with filters to help you find your dream home."
                />
                <meta
                  name="keywords"
                  content="residential property, real estate, london, property, dream home, buy, sell, manage, rental yield, stamp duty, mortgage"
                />
              </Helmet>
              <HomePage />
            </>
          } />
          <Route path="*" element={
            <>
              <Helmet>
                <title>404 | NVRE</title>
                <meta
                  name="description"
                  content="Page not found."
                />
              </Helmet>
              <NotFoundPage />
            </>
          } />
        </Routes>
      </main>
      <div style={{ minHeight: "unset" }}>
        <ToolMenu />
        {showContactPopup && <ContactUs />}
        {showPermissionPopup && <PermissionPopup />}
        {showActionPopup && <ActionPopup />}
        <div className={`${(showContactPopup || showActionPopup) ? "fixed top-0 left-0 w-screen h-screen bg-black/20 z-[99999]" : ""}`}></div>
      </div>
      {loadError === "failed" && <FloatingNotification message="Network Error" />}
      <Footer />
    </BrowserRouter>
  );
};
