import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserRelatedState {
    lang: boolean;
    permission: number;
    adminOn: boolean;
    searchName: string;
}

const initialState: UserRelatedState = {
    lang: true,
    permission: 0,
    adminOn: false,
    searchName: "",
};

export const userRelatedSlice = createSlice({
    name: "userrelated",
    initialState,
    reducers: {
        setLang: (state, action: PayloadAction<boolean>) => {
            state.lang = action.payload;
        },
        setPermission: (state, action: PayloadAction<number>) => {
            state.permission = action.payload;
        },
        setAdminOn: (state, action: PayloadAction<boolean>) => {
            state.adminOn = action.payload;
        },
        setSearchName: (state, action: PayloadAction<string>) => {
            state.searchName = action.payload;
        },
    },
});
// SELECTORS
export const getLang = (state: any) => state.userRelated.lang;
export const getPermission = (state: any) => state.userRelated.permission;
export const getAdminOn = (state: any) => state.userRelated.adminOn;
export const getSearchName = (state: any) => state.userRelated.searchName;


export const {
    setLang,
    setPermission,
    setAdminOn,
    setSearchName,
} = userRelatedSlice.actions;

export default userRelatedSlice.reducer;