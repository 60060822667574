import React from 'react';
import { NavLink } from 'react-router-dom';
import notFoundImage from '../../assets/media/background/not-found.png';
import { useSelector } from 'react-redux';
import { getLayoutHeaderHeight } from '../../features/layouts/layoutsSlice';


export default function NotFoundPage() {
    const headerHeight = useSelector(getLayoutHeaderHeight);

    return (
        <div className="flex flex-col items-center justify-center pb-20 bg-gray-100 font-poster2" style={{ minHeight: 'calc(100vh - ' + headerHeight + 'px)' }}>
            <h1 className="text-4xl font-bold text-secondary-light mb-4">Oops!</h1>
            <p className='text-8xl font-extrabold text-secondary-dark/80'>404</p>
            <img className='mt-10 h-[20vh] max-h-[calc(100vh-100px)] opacity-40' src={notFoundImage} alt="404" />
            <p className="mt-10 text-5xl font-bold text-secondary-dark/80 mb-8 font-poster2 text-center">Page Not Found</p>
            <NavLink
                to="/"
                className="px-8 py-3 font-bold bg-main text-white font-poster2 rounded-xl text-2xl hover:bg-secondary-dark transition duration-300"
            >
                GO HOME
            </NavLink>
        </div>
    );
};
